<template>
  <div class="admin-guidance-create-playbook">
    <loading-screen :is-loading="isSaving"></loading-screen>

    <!-- Page Header -->
    <div class="page-header">
      <h1 class="page-title">Edit Playbook</h1>
      <div class="actions">
        <a-button
          class="button-margin-left btn-rounded"
          icon="arrow-left"
          size="large"
          type="primary"
          @click.prevent="goBack"
          >Back</a-button
        >
      </div>
    </div>
    <!-- / Page Header -->

    <!-- Is Loading -->
    <div v-if="isLoading" class="loader">
      <a-spin></a-spin>
    </div>
    <!-- / Is Loading -->

      <!-- Form -->
      <div v-if="!isLoading" class="form">
        <a-form-item label="Playbook">
          <a-select
            v-model="playbookLocal.checklistId"
            style="width: 100%"
            size="large"
          >
          <a-select-option :value="playbook['id']">{{ playbook['displayName'] }}</a-select-option>
            <a-select-option
              v-for="p in playbooksToLink"
              :key="p.id"
              :value="p.id"
              >{{ p.displayName }}</a-select-option
            >
          </a-select>
        </a-form-item>

        <a-form-item label="Visibility">
          <a-radio-group v-model="playbookLocal.scope">
            <a-radio :value="1">Shown to organisation</a-radio>
            <!-- <a-radio :value="2">Shown to whole tenant</a-radio> -->
            <a-radio :value="3">Hidden from lists</a-radio>
          </a-radio-group>
        </a-form-item>

        <a-form-item label="Display Order">
          <a-radio-group v-model="playbookLocal.rank">
            <a-radio v-for="(rank, i) in ranks" :key="i" :value="rank.rank">{{
              rank.label
            }}</a-radio>
          </a-radio-group>
        </a-form-item>
      </div>
      <!-- / Form -->

      <a-button
        @click.prevent="save"
        class="btn-rounded save-button"
        size="large"
        type="primary"
        >Save</a-button
      >
    <!-- / Has playbooks to link -->
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import LoadingScreen from "../../../../components/LoadingScreen.vue";
import checklists from "../../../../api/checklists";
import guidanceHelpers from "../../../../helpers/guidance";
const _ = require("lodash");

export default {
  components: { LoadingScreen },

  data() {
    return {
      playbookLocal: {},

      isSaving: false,
    };
  },

  created() {
    if (!this.selectedOrganisation) {
      this.$message.info("Please select an organisation");
      this.$router.push("/admin");
    }
    if (this.playbook) {
        this.playbookLocal = {...this.checklistContent['$v']}
    } else {
        this.$message.info('Playbook not found');
        this.$router.push('/admin/guidance/playbooks');
    }
  },

  methods: {
    ...mapActions('adminPlaybooks', {
      updateChecklistContentLocally: 'updateChecklistContentLocally'
    }),

    goBack() {
      this.$router.push("/admin/guidance/playbooks/" + this.checklistId);
    },

    getValidationErrors() {
      let errors = [];
      if (!this.playbookLocal.checklistId) {
        errors.push("Please select a playbook");
      }
      return errors;
    },

    async save() {
      let vm = this;

      // Validation
      let validationErrors = this.getValidationErrors();
      if (validationErrors.length) {
        this.$message.error(validationErrors[0]);
        return false;
      }

      vm.isSaving = true;

      let params = {
        ...this.playbookLocal
      };

      checklists
        .updateChecklistContent(vm.tenantId, vm.checklistContent['$v']['id'], params)
        .then((r) => {
          vm.updateChecklistContentLocally(r.data);
          vm.$message.success("Playbook updated successfully");
          vm.$router.push("/admin/guidance/playbooks/" + r.data['$v']['checklistId']);
        })
        .catch((e) => {
          console.log(e);
        });
    },
  },

  computed: {
    ...mapGetters("missionControl", {
      presences: "presences",
    }),

    ...mapGetters("admin", {
      tenantId: "tenantId",
      selectedOrganisation: "selectedOrganisation",
      isLoading: "isLoading",
    }),

    ...mapGetters("adminPlaybooks", {
      playbooksToShow: "playbooksToShow",
      playbooksToLink: "playbooksToLink",
      checklistContents: "checklistContents"
    }),

    checklistId() {
      return this.$route.params.id;
    },

    playbook() {
        return _.find(this.playbooksToShow, {'id': this.checklistId})
    },

    checklistContent() {
        let vm = this;
        return _.find(this.checklistContents, cc => {
            return cc['$v']['checklistId'] == vm.checklistId
        });
    },

    ranks() {
      return guidanceHelpers.listRankOptions();
    },

    selectedPlaybook() {
      if (!this.playbookLocal.checklistId) {
        return null;
      }
      return _.find(this.playbooksToLink, {
        id: this.playbookLocal.checklistId,
      });
    },
  },
};
</script>

<style scoped lang="scss">
.admin-guidance-create-playbook {
  padding-bottom: 40px;

  .loader {
    background: #fff;
    padding: 50px;
    text-align: center;
  }

  .top-row {
    display: flex;
    .left {
      flex-shrink: 1;
    }
    .right {
      padding-left: 20px;
      flex-grow: 1;
    }
  }

  .form {
    background: #fff;
    padding: 20px;
    border-radius: 6px;
    font-weight: 500;
    border: 1px solid #fff;
  }

  .button-margin-left {
    margin-left: 10px;
  }

  .save-button {
    margin-top: 20px;
  }
}
</style>